'use client'

import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { ActionMeta, MultiValue, SingleValue } from 'react-select'
import configuration from '~/configuration'
import { NativeSelect } from '~/core/ui/NativeSelect'
import {
  IPromiseSearchOption,
  ISelectOption,
  SelectClassControl,
  SelectOptionProps, SelectProps, SelectSizeProps, SelectVariantProps
} from '~/core/ui/Select'
import { removeAccents } from './utils'

interface AsyncSingleSearchWithSelectProps {
  promiseOptions?: (
    params: IPromiseSearchOption
  ) => Promise<{ metadata?: { totalCount: number }; collection: never[] }>
  value?: ISelectOption | Array<ISelectOption>
  onChange: (
    newValue: SingleValue<ISelectOption> | MultiValue<ISelectOption> | null,
    actionMeta: ActionMeta<ISelectOption>
  ) => void
  size?: SelectSizeProps
  placeholder?: string
  configSelectOption?: SelectOptionProps
  destructive?: boolean
  className?: string
  classNameOverride?: SelectClassControl
  loadAsyncWhenRender?: boolean
  loadAsyncWhenOpen?: boolean
  menuPlacement?: 'top' | 'auto' | 'bottom'
  creatable?: boolean
  isDropdown?: boolean
  variant?: SelectVariantProps
  cacheOptions?: string
  extraItem?: ISelectOption
  isSearchable?: boolean
  isDisabled?: boolean
  isClearable?: boolean
  menuIsOpen?: boolean
  onInputChange?: SelectProps['onInputChange']
  isValidNewOption?: SelectProps['isValidNewOption']
  callbackClearSearchData?: () => void
  showGhostDropdownIndicator?: boolean
}

const AsyncSingleSearchWithSelect: FC<AsyncSingleSearchWithSelectProps> = ({
  promiseOptions = undefined,
  value,
  onChange,
  placeholder = 'Select',
  size = 'md',
  loadAsyncWhenRender,
  loadAsyncWhenOpen = true,
  creatable = false,
  extraItem,
  isSearchable = true,
  isClearable = true,
  callbackClearSearchData,
  ...props
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [isSearch, setSearch] = useState(false)
  const [searchInput, setSearchInput] = useState<string | undefined>()
  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [pageState, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState<number>(configuration.defaultPageSize)
  const timeoutRef = useRef<NodeJS.Timeout>()

  const handleFetch = useCallback(async ({
    page = 1,
    mergedData = false,
    search = ''
  }: {
    page: number
    mergedData: boolean
    search?: string
  }) => {
    if (page !== pageState) {
      setPage(page)
    }
    
    if (search === '') {
      setSearch(false)
    }
    
    if (promiseOptions && (!mergedData || totalCount > options.length)) {
      setLoading(true)
      const response = (await promiseOptions({
        search: search || '',
        limit: configuration.defaultPageSize,
        page
      })) || {
        collection: []
      }
      
      setLoading(false)
      setTotalCount(response.metadata?.totalCount || 0)
      const cloneData = [...options, ...response.collection]
      setOptions(handle4ExtraItem(mergedData ? cloneData : response.collection))
    }

    if (menuIsOpen === false) {
      setTimeout(() => {
        setMenuIsOpen(true)
      }, 0);
    }
  }, [pageState, options, totalCount])

  const clearSearchData = () => {
    setPage(1) 
    setTotalCount(configuration.defaultPageSize)
    setOptions([])
    callbackClearSearchData && callbackClearSearchData()
  }

  useEffect(() => {
    if (loadAsyncWhenRender) {
      handleFetch({ page: 1, mergedData: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handle4ExtraItem = (listOptions: never[]) => {
    if (extraItem) {
      let findIndexItem = listOptions.findIndex(
        (option: { value: string }) => option.value === extraItem.value
      )
      let cloneData = [...listOptions]
      if (findIndexItem !== -1) {
        cloneData[findIndexItem] = extraItem as never
      } else {
        cloneData = [extraItem as never, ...listOptions]
      }
      return cloneData
    }
    return listOptions
  }

  useEffect(() => {
    if (isSearch) 
      handleFetch({
        page: 1,
        mergedData: false,
        search: searchInput
      })
  }, [searchInput, isSearch])
  return (
    <NativeSelect
      cacheOptions={false}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      options={options}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => {
        if (loadAsyncWhenOpen) {
          handleFetch({ page: pageState, mergedData: false })
        }
      }}
      onMenuClose={() => {
        if (menuIsOpen) {
          setMenuIsOpen(false)
          setSearch(false)
          setSearchInput(undefined)
          if (!props.isDropdown) {
            clearSearchData()
          }
        }
      }}
      onMenuScrollToBottom={() => {
        handleFetch({ page: pageState + 1, mergedData: true, search: isSearch ? searchInput : '' })
      }}
      size={size}
      creatable={creatable}
      placeholder={placeholder}
      onChange={onChange}
      value={value || []}
      {...props}
      async={false}
      onInputChange={(input, actionMeta) => {
        if (actionMeta && actionMeta.action === 'input-change') {
          if (!!timeoutRef?.current) clearTimeout(timeoutRef.current)

          timeoutRef.current = setTimeout(() => {
            setSearchInput(input)
            setSearch(true)
          }, 500)
        }

        if (actionMeta.action === 'input-change' && props.onInputChange) {
          props.onInputChange(input, actionMeta)
        }
      }}
      isValidNewOption={props.isValidNewOption}
      filterOption={() => true}
    />
  )
}

AsyncSingleSearchWithSelect.displayName = 'AsyncSingleSearchWithSelect'

export { AsyncSingleSearchWithSelect }
export type { AsyncSingleSearchWithSelectProps }

