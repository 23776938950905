// @ts-nocheck
// It still has some typescript error, but it's working fine.
// Dont reuse this code if this error is not fixed. it's till under development consideration.
import {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { AsyncMultipleSearchWithSelect } from '~/core/ui/AsyncMultipleSearchWithSelect'

type AsyncMultipleSearchWithSelectOptionGroupProps = ComponentProps<
  typeof AsyncMultipleSearchWithSelect
>
const AsyncMultipleSearchWithSelectOptionGroup: FC<
  AsyncMultipleSearchWithSelectOptionGroupProps & {
    selectAllChange?: (value: boolean) => void
    showSelectOptionAll: boolean
  }
> = (props) => {
  const { t } = useTranslation()
  const [data, setData] = useState<{
    [key: string]: string[]
  }>({ parentChildsMap: {} })
  const [loaded, setLoaded] = useState(false)
  const dataRef = useRef(data)
  dataRef.current = data

  const promiseOptions = useCallback<
    Exclude<
      AsyncMultipleSearchWithSelectOptionGroupProps['promiseOptions'],
      undefined
    >
  >((params) => {
    return props.promiseOptions(params).then((result) => {
      const parentChildsMap = result.collection.reduce(
        (acc, item) =>
          item.parentId
            ? {
                ...acc,
                [item.parentId]: [
                  ...(acc[item.parentId] || []).filter(
                    (fitem) => fitem.value !== item.value
                  ),
                  item
                ]
              }
            : acc,
        data.parentChildsMap || {}
      )
      const parentMap = Object.keys(parentChildsMap).reduce(
        (acc, key) => ({
          ...acc,
          [key]: result.collection.find((item) => item.value == key)
        }),
        dataRef.current.parentMap || {}
      )
      const collection =
        params.page > 1
          ? [...dataRef.current.collection, ...result.collection]
          : result.collection
      const depMap = collection.reduce(
        (acc, item) => ({ ...acc, [item.value]: item }),
        {}
      )
      setData({
        parentMap,
        parentChildsMap,
        depMap,
        collection,
        isSearch: !!params.search
      })
      return {
        ...result,
        collection:
          params.page > 1 || params.search
            ? result.collection
            : result.collection.length > 0
            ? props.showSelectOptionAll
              ? [
                  {
                    value: '0',
                    isHideAvatar: true,
                    supportingObj: {
                      name: `${t('settings:departments:allDepartments')}`
                    }
                  },
                  ...result.collection
                ]
              : [...result.collection]
            : []
      }
    })
  }, [])

  const onChange = useCallback<
    Exclude<
      AsyncMultipleSearchWithSelectOptionGroupProps['onChange'],
      undefined
    >
  >(
    (value, actionMeta) => {
      const childIds = data?.parentChildsMap[actionMeta?.option?.value] || []
      if (actionMeta.action === 'select-option') {
        if (actionMeta.option?.value === '0') {
          return props.onChange([...data.collection], actionMeta)
        }
        if (actionMeta.option?.parentId) {
          const totalChildSelect = value.filter(
            (item) =>
              data.depMap[item.value]?.parentId === actionMeta.option?.parentId
          ).length
          const totalChilds = data.parentChildsMap[actionMeta.option?.parentId]
          if (totalChildSelect > 0 && totalChildSelect === totalChilds.length) {
            return props.onChange(
              [...value, data.parentMap[actionMeta.option?.parentId]],
              actionMeta
            )
          }
        }

        return props.onChange(
          [
            ...value.filter(
              (item) =>
                !childIds.map((child) => child.value).includes(item.value)
            ),
            ...childIds
          ],
          actionMeta
        )
      }
      if (actionMeta.action === 'deselect-option') {
        const deselectOption = actionMeta.option
        if (deselectOption?.value === '0') {
          return props.onChange([], actionMeta)
        }
        if (deselectOption?.parentId) {
          return props.onChange(
            value.filter(
              (item) =>
                item.value !== '0' && item.value != deselectOption?.parentId
            ),
            actionMeta
          )
        }
        return props.onChange(
          value.filter(
            (item) =>
              item.value !== '0' &&
              !childIds.map((child) => child.value).includes(item.value)
          ),
          actionMeta
        )
      }
      if (actionMeta.action === 'remove-value') {
        if (actionMeta.removedValue?.value === '0') {
          return props.onChange([], actionMeta)
        }
        const removedValuechildIds =
          data?.parentChildsMap[actionMeta?.removedValue?.value] || []
        return props.onChange(
          value.filter(
            (item) =>
              item.value !== '0' &&
              !removedValuechildIds
                .map((child) => child.value)
                .includes(item.value)
          ),
          actionMeta
        )
      }
      return props.onChange(value, actionMeta)
    },
    [data]
  )
  useEffect(() => {
    promiseOptions({ page: 1 }).then(() => {
      setLoaded(true)
    })
  }, [])

  const shortTerningValue = (valuesVariable) => {
    const values = valuesVariable || []
    const cpMap = values.reduce((acc, item) => {
      return {
        ...acc,
        ...(item.subordinates || []).reduce(
          (accs, subitem) => ({ ...accs, [subitem.id]: item }),
          {}
        )
      }
    }, {})
    const selectedPCMap = values.reduce((acc, item) => {
      const parent = cpMap[item.value]
      return parent
        ? { ...acc, [parent.value]: [...(acc[parent.value] || []), item] }
        : acc
    }, {})

    return values.map((item) => {
      const parent = cpMap[item.value]
      const currentChilds =
        (data.parentChildsMap && data.parentChildsMap[item?.value]) || []
      const allSelectedChilds = selectedPCMap[item.value] || []
      const allParentsChild =
        (data.parentChildsMap && data.parentChildsMap[parent?.value]) || []
      const allSelectedParentsChild = selectedPCMap[parent?.value] || []

      const isShowAll =
        props?.value?.length === data?.collection?.length && !data.isSearch
      return (item.subordinates || []).length > 0
        ? {
            ...item,
            parentId: parent?.value,
            chipHidden:
              isShowAll ||
              currentChilds.length > (allSelectedChilds?.length || 0)
          }
        : parent
        ? {
            ...item,
            parentId: parent?.value,
            chipHidden:
              isShowAll ||
              allParentsChild.length === allSelectedParentsChild.length
          }
        : {
            ...item,
            chipHidden: isShowAll
          }
    })
  }

  useEffect(() => {
    const isShowAll =
      props?.value?.length === data?.collection?.length && !data.isSearch

    if (isShowAll) {
      props.selectAllChange && props.selectAllChange(true)
    } else {
      props.selectAllChange && props.selectAllChange(false)
    }
  }, [props.value?.length, data?.collection?.length, data.isSearch])

  const isShowAll =
    props?.value?.length === data?.collection?.length && !data.isSearch

  return loaded ? (
    <AsyncMultipleSearchWithSelect
      {...props}
      value={
        isShowAll
          ? props.showSelectOptionAll
            ? [
                ...shortTerningValue(props.value),
                {
                  value: '0',
                  supportingObj: {
                    name: `${t('settings:departments:allDepartments')}`
                  },
                  chipHidden: data.collection.length > props.value.length
                }
              ]
            : [...props.value]
          : shortTerningValue(props.value)
      }
      promiseOptions={promiseOptions}
      onChange={onChange}
      classNameOverride={{
        loadingIndicator: '!p-0 !w-[10px]',
        menuList: '!max-h-[367px]',
        loadingMessage: `${t('label:loading')}`,
        noOptionsMessage: `${t('label:noOptions')}`,
        ...props.classNameOverride
      }}
      menuHeight={375}
    />
  ) : undefined
}
export default AsyncMultipleSearchWithSelectOptionGroup
